import useLayers, { ILayerAndObject } from "../../../hooks/useLayers";
import tw, { css } from "twin.macro";
import Layer from "./Layer";
import propertiesStyles from "../Properties/propertiesStyles";
import {
  FolderDeleteIcon,
  FolderIcon,
  TimesIcon,
} from "../shared/SvgComponents";
import ButtonReset from "../shared/ButtonReset";
import ILayersObject from "../../../state/models/layers/ILayersObject";
import { useContext, useEffect, useMemo, useState } from "react";
import { CanvasContext } from "../../../state/contexts/CanvasContext";
import { CanvasObject } from "../../../state/contexts/PagesContext";
import ILayers from "../../../state/models/ILayers";
import { fabric } from "fabric";
import ILayer from "../../../state/models/layers/ILayer";
import React from "react";
interface ILayersProps {
  onClose: () => void;
  sidebarIsOpen: boolean;
  disabled?: boolean;
}

const topbarStyles = {
  container: [
    tw`bg-glacier border-0 border-b border-solid border-border  flex items-center h-[45px]`,
  ],
  title: [tw`uppercase font-semibold text-lg`],
  button: [
    tw`hover:bg-hover border-0 border-solid border-l border-border ml-auto px-4 h-11`,
  ],
  icon: [tw`fill-labels w-5 h-5`],
};

const styles = {
  container: (sidebarIsOpen: boolean) => [
    tw`bg-white border border-t-0 border-l-0 border-solid border-border`,
    css`
      width: 200px;
      height: 400px;
      position: absolute;
      max-height: 400px;
      overflow-y: auto;

      top: 0;
      z-index: 50;
    `,
    propertiesStyles.scrollbar,
    sidebarIsOpen
      ? css`
          left: 320px;
        `
      : css`
          left: 0;
        `,
  ],
  bottomRight: (sidebarIsOpen: boolean) => [
    tw`bg-white border  border-solid border-border right-4 bottom-16`,
    css`
      width: 350px;
      height: 400px;
      position: absolute;
      max-height: 400px;
      overflow: hidden;
      z-index: 50;
    `,
    propertiesStyles.scrollbar,
  ],
  layersScroll: [
    css`
      max-height: calc(400px - 37px);
      overflow-y: auto;
      height: calc(400px-37px);
    `,
    propertiesStyles.scrollbar,
  ],
  heading: [],
  titleContainer: [
    tw`bg-glacier border-0 border-b border-solid border-border flex flex-row items-center text-labels w-full`,
  ],
  title: [tw`uppercase font-semibold text-lg`],
  button: [
    tw`h-full hover:bg-hover border-0 border-solid border-l border-border ml-auto px-4 disabled:hover:bg-glacier disabled:cursor-not-allowed`,
    css`
      height: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
    `,
  ],
  buttonContainer: [tw`flex items-center ml-auto h-full`],
  icon: (disabled: boolean) => [
    tw`fill-labels w-5 h-5`,
    disabled && tw`fill-buttonTextDisabled`,
  ],
};

const LayersWrapper = ({
  onClose,
  sidebarIsOpen,
  disabled = false,
}: ILayersProps) => {
  const {
    layers: layersState,
    moveLayerToDestination,
    selectLayers,
    selectedLayers,
    changeLayerName,
    selectedPage,
    toggleLock,
    toggleVisibility,
    toggleCollapse,
    groupLayers,
    ungroupLayers,
  } = useLayers();

  const selectedPageLayers = layersState.pageLayers.find(
    (x) => x.name === selectedPage,
  );

  const canvas = useContext(CanvasContext);

  function handleDrop(itemId: string, destinationId: string) {
    moveLayerToDestination(selectedPage, itemId, destinationId);
  }

  function handleLayerNameChange(key: string, name: string, index: string) {
    changeLayerName(selectedPage, key, name, index);
  }

  function getLayers(page: string, layers: ILayers) {
    if (!canvas) return null;
    const pageLayers = layers.pageLayers.find((x) => x.name === page);
    if (!pageLayers) return null;
    const layersArray: ILayerAndObject[] = [];
    const keys = Object.keys(pageLayers.layers);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const layer = pageLayers.layers[key];
      if (layer) {
        const objects = canvas.getObjects();
        const layerObj = objects.find(
          (x) => x.name === layer.id,
        ) as CanvasObject;
        if (!layerObj) {
          if (!layer.id.includes("group")) continue;
        }
        layersArray.push({
          ...pageLayers.layers[key],
          object: layerObj,
          layerIndex: key,
        });
      }
    }

    return layersArray.reverse().map((layer) => (
      <div key={layer.id} onClick={(e) => handleSelectLayers(e, layer.id)}>
        <Layer
          onChange={handleLayerNameChange}
          layer={layer}
          onDrop={handleDrop}
          isActive={Boolean(selectedLayers.find((x) => x.index === layer.id))}
          toggleHide={toggleVisibility}
          toggleLock={toggleLock}
          toggleCollapse={toggleCollapse}
          disabled={disabled}
        />
      </div>
    ));
  }

  function handleGroup() {
    if (!canvas || !canvas.getActiveObject()) return;
    const activeObj = canvas.getActiveObject();
    if (groupSelected()) {
      ungroupLayers();
      return;
    }
    if (activeObj) {
      groupLayers(activeObj);
    }
  }

  function groupSelected() {
    if (!canvas) return false;
    if (selectedLayers.length === 1 && !checkGroupDisbled()) {
      const pageLayers = layersState.pageLayers.find(
        (x) => x.name === selectedPage,
      );
      if (pageLayers) {
        const layerKey = Object.keys(pageLayers.layers).find(
          (x) => pageLayers.layers[x].id === selectedLayers[0].index,
        );
        if (
          layerKey &&
          pageLayers.layers[layerKey] &&
          pageLayers.layers[layerKey].layers !== undefined
        ) {
          return true;
        }
      }
    }
    return false;
  }

  function handleSelectLayers(e: React.MouseEvent, id: string) {
    e.stopPropagation();
    selectLayers(id);
  }

  function checkGroupDisbled() {
    if (!canvas || !canvas.getActiveObject()) return true;
    const activeObjs = canvas.getActiveObjects();

    if (activeObjs.length) {
      // if any of the objects selected are already grouped, return true.
      // if any of the objects are groups and multiple objects are selected, return true.
      // otherwise, return false
      const pageLayers = layersState.pageLayers.find(
        (x) => x.name === selectedPage,
      );

      if (!pageLayers) return true;
      const layerArr = Object.keys(pageLayers.layers).reduce((arr, key) => {
        arr.push(pageLayers.layers[key]);
        return arr;
      }, [] as ILayer[]);
      const groupedSelections = selectedLayers.filter((x) =>
        layerArr.find((y) => y.id === x.index) ? false : true,
      );

      if (activeObjs.length === 1) {
        if (selectedLayers.length) {
          const selectedLayer = layerArr.find(
            (x) => selectedLayers[0].index === x.id,
          );
          if (selectedLayer && selectedLayer.layers !== undefined) return false;
        }
      }
      if (groupedSelections.length) return true;

      return false;
    }
    return true;
  }
  const groupingDisabled = useMemo(() => checkGroupDisbled(), [selectedLayers]);
  const singleGroupSelected = useMemo(() => groupSelected(), [selectedLayers]);
  return (
    <React.Fragment>
      <div css={topbarStyles.container} key={selectedPage}>
        <div css={propertiesStyles.sidebarHeading}>Layers</div>
        <div css={styles.buttonContainer}>
          <ButtonReset
            onClick={handleGroup}
            css={styles.button}
            disabled={groupingDisabled || disabled}
          >
            {(!singleGroupSelected || groupingDisabled) && (
              <FolderIcon styles={styles.icon(groupingDisabled)} />
            )}
            {singleGroupSelected && !groupingDisabled && (
              <FolderDeleteIcon styles={styles.icon(false)} />
            )}
          </ButtonReset>
          <ButtonReset onClick={onClose} css={styles.button}>
            <TimesIcon styles={styles.icon(false)} />
          </ButtonReset>
        </div>
      </div>
      <div css={styles.layersScroll}>
        {getLayers(selectedPage, layersState)}
        {/* {layers.map((layer) => {
          return (
            <div key={layer.id} onClick={() => selectLayers(layer.id)}>
              <Layer
                onChange={handleLayerNameChange}
                layer={layer}
                onDrop={handleDrop}
                isActive={Boolean(
                  selectedLayers.find((x) => x.index === layer.id)
                )}
                toggleHide={toggleVisibility}
                toggleLock={toggleLock}
              />
            </div>
          );
        })} */}
      </div>
    </React.Fragment>
  );
};

export default LayersWrapper;
