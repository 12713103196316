import { Canvas } from "fabric/fabric-impl";
import RESOLUTION from "../../../constants/RESOLUTION";
import { fabric } from "fabric";
import getOverlayObjectsOld from "./getOverlayObjectsOld";
import {
  LetterFoldTypes,
  LetterSettingsMargins,
} from "../../../state/slices/letterSettings";
import { CanvasObject } from "../../../state/contexts/PagesContext";
import DesignerProductTypes from "../../../../data/models/DesignerProductTypes";
import Sizes from "../../../constants/Sizes";
import DesignerSizes from "../../../../data/models/DesignerSizes";
export default async function insertBackgroundAndOverlayOld(
  canvas: Canvas,
  width: number,
  height: number,
  productType: DesignerProductTypes,
  size: DesignerSizes,
  overlay?: string,
  envelopeType?: string,
  fold?: LetterFoldTypes,
  insertAddressing?: boolean,
  margins?: LetterSettingsMargins,
) {
  const isLetter = productType === DesignerProductTypes.LETTER;
  const isBrochure = productType === DesignerProductTypes.BROCHURE;

  const background = new fabric.Rect({
    width:
      productType === DesignerProductTypes.POSTCARD
        ? (width - 0.25) * RESOLUTION
        : width * RESOLUTION,
    height:
      productType === DesignerProductTypes.POSTCARD
        ? (height - 0.25) * RESOLUTION
        : height * RESOLUTION,
    fill: "white",
    strokeWidth: 0,
    name: "background",
    stroke: "black",
    selectable: false,
    lockMovementX: true,
    lockMovementY: true,
    perPixelTargetFind: true,
    strokeUniform: true,
  });

  const bleed = new fabric.Rect({
    width: width * RESOLUTION - 2,
    height: height * RESOLUTION - 2,
    stroke: "blue",
    strokeWidth: 2,
    name: "bleed",
    selectable: false,
    lockMovementX: true,
    strokeUniform: true,
    lockMovementY: true,
    fill: "rgba(0,0,0, 0)",
    perPixelTargetFind: true,
  });

  const safeArea = generateSafeArea(width, height);

  canvas.insertAt(background, 0, false);
  canvas.add(bleed);
  canvas.add(safeArea);
  safeArea.bringToFront();
  canvas.centerObject(background);
  canvas.centerObject(safeArea);
  canvas.centerObject(bleed);

  if (!isLetter && !isBrochure) {
    const bleedLabel = new fabric.Text("Bleed", {
      fill: "blue",
      originX: "center",
      originY: "center",
      left: bleed.getCenterPoint().x,
      top: bleed.getCenterPoint().y - bleed.getScaledHeight() / 2 - 20,
      name: "overlay",
      fontSize: 26,
      evented: false,
      selectable: false,
      fontFamily: "Roboto",
    });
    canvas.insertAt(bleedLabel, 1, false);
  }

  if (margins) {
    const marginObjects = generateMargins(margins, background);
    canvas.add(...marginObjects);
  }
  const safeAreaLabel = new fabric.Text("Safe Area", {
    fill: "green",
    originX: "center",
    originY: "center",
    left: safeArea.getCenterPoint().x,
    top: safeArea.getCenterPoint().y - safeArea.getScaledHeight() / 2 - 20,
    name: "overlay",
    fontSize: 26,
    evented: false,
    selectable: false,
    fontFamily: "Roboto",
  });
  canvas.insertAt(safeAreaLabel, 3, false);
  canvas.renderAll();
  if (overlay) {
    await getOverlayObjectsOld(
      overlay,
      canvas,
      background,
      width === 8.5 && height === 11 ? envelopeType : undefined,
      width === 11 && height === 8.5 ? fold : undefined,
      insertAddressing,
    );
  }

  canvas.renderAll();

  return background;
}

export function generateMargins(
  margins: LetterSettingsMargins,
  background: CanvasObject,
) {
  const left = background.left ?? 0;
  const top = background.top ?? 0;
  const width = background.width ?? 0;
  const height = background.height ?? 0;
  const right = left + width;
  const bottom = top + height;
  const settings = {
    //@ts-ignore
    __margin: true,
    name: "overlay",
    selectable: false,
    lockMovementX: true,
    lockMovementY: true,
    stroke: "#313131",
    strokeWidth: 2,
    strokeUniform: true,
    strokeDashArray: [8, 8],
  };
  const marginLeft = new fabric.Polyline(
    [
      { x: left + margins.left, y: top },
      { x: left + margins.left, y: bottom },
    ],
    {
      ...settings,
    },
  );
  const marginRight = new fabric.Polyline(
    [
      { x: right - margins.right, y: top },
      { x: right - margins.right, y: bottom },
    ],
    {
      ...settings,
    },
  );
  const marginTop = new fabric.Polyline(
    [
      { x: left, y: top + margins.top },
      { x: right, y: top + margins.top },
    ],
    {
      ...settings,
    },
  );
  const marginBottom = new fabric.Polyline(
    [
      { x: left, y: bottom - margins.bottom },
      { x: right, y: bottom - margins.bottom },
    ],
    {
      ...settings,
    },
  );
  return [marginLeft, marginRight, marginTop, marginBottom];
}

export function generateSafeArea(width: number, height: number) {
  return new fabric.Rect({
    width: (width - 0.5) * RESOLUTION - 2,
    height: (height - 0.5) * RESOLUTION - 2,
    stroke: "green",
    strokeUniform: true,
    strokeWidth: 2,
    strokeDashArray: [8, 8],
    name: "overlay",
    selectable: false,
    lockMovementX: true,
    lockMovementY: true,
    fill: "rgba(0,0,0, 0)",
    perPixelTargetFind: true,
  });
}
